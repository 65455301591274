import * as React from 'react';
import { ICultureInfoFormatter } from '@msdyn365-commerce/core';

export interface IOrderDetailsContainerRowProps {
    name: string | undefined;
    itemId: string | undefined;
    imageUrl: string | undefined;
    productUrl: string | undefined;
    quantity: number | undefined;
    netAmount: number;
    strikeAmount: number;
    salesStatus: string;
    quantityLabel: string;
    buyAgainButton: React.ReactNode;
    renderedImage: JSX.Element;
    cultureFormatter: ICultureInfoFormatter;
}

export const OrderDetailsContainerRowComponent: React.FC<IOrderDetailsContainerRowProps> = (props: IOrderDetailsContainerRowProps) => {
    const {
        name = '',
        itemId = '',
        productUrl = '',
        quantity = 0,
        netAmount = 0,
        strikeAmount = 0,
        salesStatus,
        quantityLabel,
        buyAgainButton,
        renderedImage,
        cultureFormatter
    } = props;

    return (
        <>
            <div className={`ms-order-details__sales-line ms-order-details__sales-line__selection-disabled ms-order-details__sales-line__selection-global-disabled ms-order-details__sales-line__available-product`}>
                <div className="ms-order-details__sales-line-checkbox-control ms-order-details__sales-line-checkbox-control__selection-disabled ms-order-details__sales-line-checkbox-control__selection-global-disabled ms-order-details__sales-line-checkbox-control__available-product ms-order-details__sales-line-checkbox-control__unselected">
                    <div className="msc-cart-line">
                        <a className="msc-cart-line__product-image" href={productUrl} aria-label={name}>
                            {renderedImage}
                        </a>
                        <div className="msc-cart-line__content">
                            <div className="msc-cart-line__product">
                                <a className="msc-cart-line__product-title" href={productUrl}>
                                    {`${itemId} - ${name}`}
                                </a>
                                <div className="msc-cart-line__product-price">
                                    <span className="msc-price msc-cart-line__product-discount-value">
                                        { strikeAmount ? (
                                            <span className="msc-price__strikethrough">{cultureFormatter.formatCurrency(strikeAmount)}</span>
                                        ) : null }
                                        <span aria-hidden="true">
                                            <span className="msc-price__actual" itemProp="price">{cultureFormatter.formatCurrency(netAmount)}</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="msc-cart-line__quantity">
                                <label className="quantity-label">{quantityLabel}
                                </label>
                                <span className="quantity-value">{quantity}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {buyAgainButton}
            </div>
            <span className="ms-order-details__sales-status">{salesStatus}</span>
        </>
    );
}
