import * as React from 'react';
import { INodeProps, Node } from "@msdyn365-commerce-modules/utilities";

export interface INoShipmentTrackingProps {
    noShipmentTrackingNodeProps: INodeProps;
    noShipmentTrackingResources: INoShipmentTrackingResources;
}

export interface INoShipmentTrackingResources {
    noShippingFoundText: string;
}

export const NoShipmentTrackingComponent: React.FC<INoShipmentTrackingProps> = (props: INoShipmentTrackingProps) => {
    const {
        noShipmentTrackingNodeProps,
        noShipmentTrackingResources: {
            noShippingFoundText = ''
        }
    } = props;

    const baseClassName = noShipmentTrackingNodeProps.className

    return (
        <Node {...noShipmentTrackingNodeProps}>
            <div className={`${baseClassName}__header`}>{noShippingFoundText}</div>
        </Node>
    );
}