import React from 'react';
import Table from 'reactstrap/lib/Table';
import classnames from 'classnames';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { IAriServiceAssemblyPartProductCombinations } from '../model-viewer';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import { CommercePropertyValueEnum } from '../../../modules/allowable-freight/utils/CommercePropertyValueEnum';
import getExtensionPropertyValueByKey from '../../../utils/getExtensionPropertyValueByKey';

interface IAriPArtsListTableProps {
    parts: IAriServiceAssemblyPartProductCombinations[];
    tableHeadings: string[];
    baseClassName: String;
    goToId(id: string): void;
    addPartToCart(part: ProductSearchResult, partId: string): void;
    partIdPrefix: string;
    selectedRow: string | undefined;
    isAuthenticated: boolean;
    requiresAuthentication: boolean;
    loginToViewPricingText: string;
    unavailableText: string;
    addToCartText: string;
    signInUrl: string;
    isMobile: boolean;
    isDrawerOpen: boolean;
    closePartDrawer(): void;
    priceFormatter: (price: string | number, currencyCode?: string | undefined) => string;
    mfrCode: string;
}

/**
 * Component to display a list of parts
 * @param props props
 */
export const AriPartsListTableComponent: React.FC<IAriPArtsListTableProps> = props => {
    const {
        tableHeadings,
        parts,
        baseClassName,
        goToId,
        addPartToCart,
        partIdPrefix,
        selectedRow,
        isAuthenticated,
        requiresAuthentication,
        loginToViewPricingText,
        signInUrl,
        isMobile,
        isDrawerOpen,
        unavailableText,
        addToCartText,
        closePartDrawer,
        priceFormatter
    } = props;

    const [yPosition, setYPosition] = React.useState(0);
    React.useEffect(() => {
        //Scroll event handling
        window.removeEventListener('scroll', () => setYPosition(window.pageYOffset));
        window.addEventListener('scroll', () => setYPosition(window.pageYOffset), { passive: true });
        window.removeEventListener('scroll', () => setYPosition(window.pageYOffset));
        return;
    });

    const scrollDownClickHandler = (id: string) => {
        const element = document.getElementById(id);
        return window.scrollTo(0, element ? element.getBoundingClientRect().top : 0);
    };
    const selectedPartIdName = `${baseClassName}__part-selection-popover`;
    return (
        <>
            <div className={`${baseClassName}__list`}>
                {!isMobile ? (
                    <Table className={'list-view__table'}>
                        <thead>
                            <tr>
                                {tableHeadings.map(th => (
                                    <th key={th}>{th}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {parts &&
                                parts.map(part => {
                                    const product = part.Product;
                                    const partId = product && product.ItemId;
                                    if (product?.ItemId === 'HOM538938002') {
                                        debugger;
                                    }
                                    const description = (product && product.Name) || part.Description;
                                    // TODO: Don't use hardcoded key
                                    const currentInvString =
                                        product &&
                                        getExtensionPropertyValueByKey(
                                            product.ExtensionProperties,
                                            'inventory',
                                            CommercePropertyValueEnum.string
                                        );
                                    let currentInventory = parseInt(currentInvString as string, 10);
                                    if (isNaN(currentInventory)) {
                                        currentInventory = 0;
                                    }
                                    const oosUpperBound = part.Product?.oosUpperBoundLimit || 0;
                                    const canAddToCart = part.Product && currentInventory > oosUpperBound && part.Product?.isEligible;
                                    return (
                                        <tr
                                            key={part.PartId}
                                            data-part={JSON.stringify(part)}
                                            id={`${partIdPrefix}${part.Tag!}`}
                                            className={classnames(
                                                (selectedRow && selectedRow === part.Tag && `${baseClassName}__selected-row`) || '',
                                                `${baseClassName}__parts-row`
                                            )}
                                        >
                                            <td>
                                                <button className={`${baseClassName}__ref-btn`} onClick={() => goToId(part.Tag!)}>
                                                    {part.Tag}
                                                </button>
                                            </td>
                                            <td>{partId || 'N/A'}</td>
                                            <td>{description}</td>
                                            {isAuthenticated || !requiresAuthentication ? (
                                                <React.Fragment>
                                                    <td>{currentInventory}</td>
                                                    <td>{priceFormatter(part.Product?.BasePrice || 0)}</td>
                                                    <td>{priceFormatter(part.Product?.Price || 0)}</td>
                                                    {canAddToCart ? (
                                                        <>
                                                            <td>
                                                                <input
                                                                    className='quantity-input'
                                                                    defaultValue='1'
                                                                    type='number'
                                                                    disabled={!part.Product}
                                                                    min={1}
                                                                    id={part.PartId}
                                                                />
                                                            </td>
                                                            <td>
                                                                {' '}
                                                                <button
                                                                    className={`${baseClassName}__ref-btn add-to-cart-btn`}
                                                                    onClick={() => addPartToCart(part.Product!, part.PartId!)}
                                                                >
                                                                    {addToCartText}
                                                                </button>
                                                                <p className={`hide-status hide-status-${part.PartId}`}></p>
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <td colSpan={2}>
                                                            <button className={`${baseClassName}__ref-btn disabled`} disabled={true}>
                                                                {unavailableText}
                                                            </button>{' '}
                                                        </td>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <td>{currentInventory}</td>
                                                    <td>{priceFormatter(part.Product?.BasePrice || 0)}</td>
                                                    <td colSpan={3}>
                                                        <a href={signInUrl}>{loginToViewPricingText}</a>
                                                    </td>
                                                </React.Fragment>
                                            )}
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                ) : (
                    <ListGroup className='ari-model-viewer__parts-list'>
                        {parts &&
                            parts.map(part => (
                                <ListGroupItem key={part.PartId} active>
                                    <ListGroupItemHeading className='ari-model-viewer__parts-list-heading'>
                                        <span className='ari-model-viewer__parts-list-heading--part-id'>
                                            {`${tableHeadings[0]}: `}
                                            {part.Tag}
                                        </span>
                                        <span className='ari-model-viewer__parts-list-heading--part-number'>
                                            {`${tableHeadings[1]}: `}
                                            {`${props.mfrCode}${part.Sku}`}
                                        </span>
                                    </ListGroupItemHeading>
                                    <ListGroupItemText>
                                        <span className='ari-model-viewer__parts-list-part-desc'>
                                            {`${tableHeadings[2]}: ${part.Description}`}
                                        </span>
                                    </ListGroupItemText>
                                    <ListGroupItemText>
                                        {isAuthenticated || requiresAuthentication ? (
                                            <div className='ari-model-viewer__parts-list-item-details'>
                                                <span className='ari-model-viewer__parts-list-item-details--part-price'>{`${
                                                    tableHeadings[4]
                                                }: ${priceFormatter(part.Product?.Price || 0)}`}</span>
                                                <span className='ari-model-viewer__parts-list-item-details--part-qty'>{`${
                                                    tableHeadings[3]
                                                }: ${part.Qty === '' ? 0 : parseInt(part.Qty!, 10)}`}</span>
                                            </div>
                                        ) : (
                                            <a href={signInUrl}>{loginToViewPricingText}</a>
                                        )}
                                    </ListGroupItemText>
                                    <ListGroupItemText>
                                        {isAuthenticated || requiresAuthentication ? (
                                            <div className='quantity-btns'>
                                                {part.Product ? (
                                                    <div>
                                                        <input
                                                            className='quantity-input'
                                                            defaultValue='1'
                                                            type='number'
                                                            disabled={!part.Product}
                                                            min={1}
                                                            id={part.PartId}
                                                        />

                                                        <button
                                                            className={`${baseClassName}__ref-btn addtocart`}
                                                            onClick={() => addPartToCart(part.Product!, part.PartId!)}
                                                        >
                                                            {addToCartText}
                                                        </button>
                                                        <p className={`hide-status-${part.PartId}`}></p>
                                                    </div>
                                                ) : (
                                                    <button className={`${baseClassName}__ref-btn addtocart disabled`} disabled={true}>
                                                        {unavailableText}
                                                    </button>
                                                )}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </ListGroupItemText>
                                </ListGroupItem>
                            ))}
                    </ListGroup>
                )}
                {yPosition <= 0 && (
                    <div className='scroll-down-chevron' onClick={e => scrollDownClickHandler(`${baseClassName}__list`)}>
                        scroll down
                    </div>
                )}
            </div>
            <div
                id={`${selectedPartIdName}`}
                className={`${selectedPartIdName} ${isDrawerOpen ? `${selectedPartIdName}--opened` : `${selectedPartIdName}--closed`}`}
            >
                <div className={`${selectedPartIdName}-table-wrapper`}>
                    <Table className={`${selectedPartIdName}-table`}>
                        <thead>
                            <tr>
                                {tableHeadings.map(th => (
                                    <th key={th}>{th}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody id={`${selectedPartIdName}-table--body`}></tbody>
                    </Table>
                    <div className={`${selectedPartIdName}-close-drawer`} onClick={closePartDrawer}>
                        X
                    </div>
                </div>
            </div>
        </>
    );
};
