/* eslint-disable react/jsx-no-bind */
import React from 'react';
import Table from 'reactstrap/lib/Table';
import { ICourseEntity } from '../../../actions/DataServiceEntities.g';
import { addCourseToCartAsync } from '../../../actions/DataActionExtension.g';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { ICartState } from '@msdyn365-commerce/global-state';
import { ICoreContext } from '@msdyn365-commerce/core';
import * as Msdyn365 from '@msdyn365-commerce/core';
import { IEducationTrainingViewState } from '../education-training';

export interface ICourseTableComponentResources {
    courseListHeading: string;
    courseListResultsHeading: string;
    courseNameHeading: string;
    courseDateHeading: string;
    courseRegistrationDetailsHeading: string;
    courseAddToCartHeading: string;
    addToCartButtonLabel: string;
    startLabel: string;
    endLabel: string;
    registrationEndsLabel: string;
    maximumAttendeesLabel: string;
    currentAttendeesLabel: string;
    courseExpiredMessage: string;
    courseFullMessage: string;
}

interface ICourseTableComponentProps {
    courseList: ICourseEntity[];
    resources: ICourseTableComponentResources;
    totalCount: number;
    cart: ICartState | undefined;
    channelId: number;
    ctx: ICoreContext;
    additionalInfo: Msdyn365.RichText | undefined;
    viewState: IEducationTrainingViewState;
    setLoading: (setLoading: boolean) => void;
}

export const CourseTableComponent: React.FC<ICourseTableComponentProps> = props => {
    const { courseList, resources, totalCount, cart, ctx, additionalInfo, viewState, setLoading } = props;
    const {
        courseListHeading,
        courseListResultsHeading,
        courseNameHeading,
        courseDateHeading,
        courseRegistrationDetailsHeading,
        courseAddToCartHeading,
        addToCartButtonLabel,
        startLabel,
        endLabel,
        registrationEndsLabel,
        maximumAttendeesLabel,
        currentAttendeesLabel,
        courseExpiredMessage,
        courseFullMessage
    } = resources;
    const [outerWidth, setOuterWidth] = React.useState(0);
    const [errMessage, setErrMessage] = React.useState('');

    React.useEffect(() => {
        //Set initial outerWidth
        setOuterWidth(window.outerWidth);
        //Resize window event handling
        window.removeEventListener('resize', () => setOuterWidth(window.outerWidth));
        window.addEventListener('resize', () => setOuterWidth(window.outerWidth), { passive: true });
        return window.removeEventListener('resize', () => setOuterWidth(window.outerWidth));
    });

    const fmt: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        timeZoneName: 'short',
        second: undefined,
        hour: 'numeric',
        minute: '2-digit'
    };

    async function _handleAddToCart(e: React.MouseEvent<HTMLDivElement>) {
        setLoading(true);
        // TODO:Move this function to the parent file. No api calls should be made from display components.
        const cartId = cart?.cart.Id;
        const target = e.target as HTMLDivElement; // TODO:We don't need to reference elements in react like this. We can use state to have a success message / button without manipulating the element.

        if (!cartId || !target.id) {
            setLoading(false);
            return;
        }

        try {
            void (await addCourseToCartAsync({ callerContext: ctx.actionContext }, cartId, target.id));
            void (await cart?.refreshCart({})); // @sylver - Added refresh cart here to update the mini cart on action completion.
            target.textContent = 'Added to Cart!'; //TODO: Use Resource
            target.id = '';
            setErrMessage('');
        } catch (e) {
            setErrMessage((e as { name: string }).name);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={`course-list ${viewState.isLoading ? 'is-loading' : ''}`}>
            {' '}
            <div className={`course-list ${viewState.isLoading ? 'is-loading' : ''}__icon`} />
            {/*TODO: Use BEM CSS */}
            {outerWidth > 768 ? (
                <Table className={'list-view__table'}>
                    <thead>
                        <tr>
                            <th colSpan={4}>
                                {courseListHeading} ({totalCount} {courseListResultsHeading}) <br />
                                <br />
                                {/*{additionalInfo && <p className='infoMessage'>{additionalInfo}</p>}*/}
                                {additionalInfo && <Msdyn365.RichTextComponent className='infoMessage' text={additionalInfo} />}
                                {errMessage && (
                                    <>
                                        <br />
                                        <p className='errMessage'>{errMessage}</p>
                                    </>
                                )}
                            </th>
                        </tr>
                        <tr>
                            <th>{courseNameHeading}</th>
                            <th>{courseDateHeading}</th>
                            <th>{courseRegistrationDetailsHeading}</th>
                            <th>{courseAddToCartHeading}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courseList.map(c => {
                            return (
                                <tr key={c.RecId}>
                                    <td>
                                        {c.HRMCourseId} - ${c.GDNCourseFee}
                                        <br />
                                        {c.Description}
                                        {/* <br /> */}
                                        {/* {c.Organizer} */}
                                        {/* John Thompson */}
                                    </td>
                                    <td>
                                        <b>{startLabel}</b>
                                        {c.StartDateTime.toLocaleString('en-us', fmt)} <br />{' '}
                                        {/* TODO: Get locale from context, not hardcode */}
                                        <b>{endLabel}</b>
                                        {c.EndDateTime.toLocaleString('en-us', fmt)} <br />
                                        {/* <b>Location: </b> */}
                                        {/* {c.Location} */}
                                        {/* Tampa Bay, Florida */}
                                    </td>
                                    <td>
                                        <b>{registrationEndsLabel}</b>
                                        {c.LastDateOfSignup.toLocaleString('en-us', fmt)}
                                        <br />
                                        <b>{maximumAttendeesLabel}</b>
                                        {c.MaxAttendees}
                                        <br />
                                        <b>{currentAttendeesLabel}</b>
                                        {c.CurrentAttendees}
                                    </td>
                                    <td>
                                        {c.CourseStatus === 'Expired' ? courseExpiredMessage : ''} {/* TODO: Consider an enum */}
                                        {c.CourseStatus === 'Full' ? courseFullMessage : ''}
                                        {c.CourseStatus === 'Valid' && (
                                            // @ts-ignore
                                            <div
                                                className={`btn ${viewState.isLoading ? 'is-loading' : ''}`}
                                                id={c.HRMCourseId}
                                                onClick={_handleAddToCart}
                                            >
                                                {addToCartButtonLabel}
                                            </div>
                                        )}
                                        {/* HOP532180054 */} {/*HOP532104757*/}
                                        {/* update ext.HRMCOURSETABLE set HRMCOURSEID = 'HOP532180054' where HRMCOURSEID = 'HOP532180054' */}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            ) : (
                <ListGroup>
                    <h2>
                        {courseListHeading} ({totalCount} {courseListResultsHeading})
                        <br />
                        <br />
                        {additionalInfo && <p className='infoMessage'>{additionalInfo}</p>}
                        {errMessage && (
                            <>
                                <br />
                                <p className='errMessage'>{errMessage}</p>
                            </>
                        )}
                    </h2>
                    {courseList.map(c => {
                        return (
                            <ListGroupItem key={c.RecId}>
                                <ListGroupItemHeading>{c.HRMCourseId}</ListGroupItemHeading>
                                <ListGroupItemText>{c.Description}</ListGroupItemText>
                                <ListGroupItemText>
                                    <b>{startLabel}</b>
                                    {c.StartDateTime.toLocaleString('en-us', fmt)} <br />
                                    <b>{endLabel}</b>
                                    {c.EndDateTime.toLocaleString('en-us', fmt)}
                                </ListGroupItemText>
                                <ListGroupItemText>
                                    <b>{registrationEndsLabel}</b>
                                    {c.LastDateOfSignup.toLocaleString('en-us', fmt)}
                                    <br />
                                    <b>{maximumAttendeesLabel}</b>
                                    {c.MaxAttendees}
                                    <br />
                                    <b>{currentAttendeesLabel}</b>
                                    {c.CurrentAttendees}
                                </ListGroupItemText>
                                <ListGroupItemText>
                                    {c.CourseStatus === 'Expired' ? courseExpiredMessage : ''}
                                    {c.CourseStatus === 'Full' ? courseFullMessage : ''}
                                    {c.CourseStatus === 'Valid' && (
                                        // @ts-ignore
                                        <div className='btn' id={c.HRMCourseId} onClick={_handleAddToCart}>
                                            {addToCartButtonLabel}
                                        </div>
                                    )}
                                </ListGroupItemText>
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
            )}
        </div>
    );
};
