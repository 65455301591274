import * as React from 'react';
import { INodeProps, Node } from "@msdyn365-commerce-modules/utilities";

export interface IShipmentTrackingProps {
    shipmentTrackingNodeProps: INodeProps;
    shipmentTrackingResources: IShipmentTrackingResources;
    containerId: string | undefined;
    weight: number | undefined;
    shippingCarrier: string | undefined;
    trackingNumber: string | undefined;
    trackingUrl: string | undefined;
}

export interface IShipmentTrackingResources {
    headingText: string;
    containerIdLabel: string;
    weightLabel: string;
    shippingCarrierLabel: string;
    trackingLabel: string;
    trackthisPackageLinkText: string;
}

export const ShipmentTrackingComponent: React.FC<IShipmentTrackingProps> = (props: IShipmentTrackingProps) => {
    const {
        shipmentTrackingNodeProps,
        shipmentTrackingResources: {
            headingText = '',
            containerIdLabel = '',
            weightLabel = '',
            shippingCarrierLabel = '',
            trackingLabel = '',
            trackthisPackageLinkText = ''
        },
        containerId = '',
        weight = 0,
        shippingCarrier = '',
        trackingNumber = '',
        trackingUrl
    } = props;

    const baseClassName = shipmentTrackingNodeProps.className

    return (
        <Node {...shipmentTrackingNodeProps}>
            <div className={`${baseClassName}__header`}>{headingText}</div>
            <span className={`${baseClassName}__info`}>{containerIdLabel}{containerId}</span>
            <span className={`${baseClassName}__info`}>{weightLabel}{weight}</span>
            <span className={`${baseClassName}__info`}>{shippingCarrierLabel}{shippingCarrier}</span>
            <span className={`${baseClassName}__info`}>{trackingLabel}{trackingNumber}</span>
            { trackingUrl ? (
                <a className={`${baseClassName}__link`} href={`${trackingUrl}`}>{trackthisPackageLinkText}</a>
            ) : null }
        </Node>
    );
}