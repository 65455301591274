import { getProductPageUrlSync, ObjectExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { ICoreContext } from '@msdyn365-commerce/core';
import React from 'react';
import Table from 'reactstrap/lib/Table';
import { IGdnFreightDetails, IGdnInvoiceDetailsResponseEntity, IGdnInvoiceLineEntity } from '../../../actions/DataServiceEntities.g';
import { formatAmount } from '../gdn-invoice-details';

export interface IProductsTableComponentResources {
    itemIdHeading: string;
    descriptionHeading: string;
    quantityHeading: string;
    listPriceHeading: string;
    unitPriceHeading: string;
    totalHeading: string;
    custItemNumLabel: string;
}

interface IProductsTableComponentProps {
    className: string;
    currencyCode: string;
    invoiceDetails: IGdnInvoiceDetailsResponseEntity | undefined;
    invoiceLines: IGdnInvoiceLineEntity[] | undefined;
    ctx: ICoreContext;
    resources: IProductsTableComponentResources;
}

export const InvoiceDetailsProductsTableComponent: React.FC<IProductsTableComponentProps> = props => {
    const { className, currencyCode, ctx, invoiceDetails, invoiceLines, resources } = props;
    const {
        itemIdHeading,
        descriptionHeading,
        quantityHeading,
        listPriceHeading,
        unitPriceHeading,
        totalHeading,
        custItemNumLabel
    } = resources;

    const freightList = invoiceDetails?.FreightList;
    const combinedFreightList: IGdnFreightDetails[] = [];
    if (freightList) {
        const reducedFreightList = freightList.reduce((acc, fl) => {
            const { FreightAmount, FreightCode, FreightDescription } = fl;

            if (FreightCode && FreightAmount) {
                if (!acc[FreightCode]) {
                    acc[FreightCode] = [];
                }
                if (acc[FreightCode].length === 0) {
                    acc[FreightCode].push({
                        FreightAmount: FreightAmount,
                        FreightCode: FreightCode,
                        FreightDescription: FreightDescription
                    });
                } else {
                    acc[FreightCode][0].FreightAmount += FreightAmount;
                }
            }
            return acc;
        }, {});

        for (const code in reducedFreightList) {
            if (Object.prototype.hasOwnProperty.call(reducedFreightList, code)) {
                const details = reducedFreightList[code];
                combinedFreightList.push(...details);
            }
        }
    }
    return (
        <div className={`${className}__products`}>
            <Table className={`${className}__products-table`}>
                <thead>
                    <tr>
                        <th className={`${className}__products-table--col-1`}></th>
                        <th className={`${className}__products-table--col-2`}>{itemIdHeading}</th>
                        <th className={`${className}__products-table--col-3`}>{descriptionHeading}</th>
                        <th className={`${className}__products-table--col-4`}>{quantityHeading}</th>
                        <th className={`${className}__products-table--col-5`}>{listPriceHeading}</th>
                        <th className={`${className}__products-table--col-6`}>{unitPriceHeading}</th>
                        <th className={`${className}__products-table--col-7`}>{totalHeading}</th>
                    </tr>
                </thead>
                <tbody>
                    {invoiceLines &&
                        invoiceLines.map((line, index) => {
                            let productUrl = '';
                            if (
                                !ObjectExtensions.isNullOrUndefined(line?.Description) &&
                                !ObjectExtensions.isNullOrUndefined(line?.ProductId)
                            ) {
                                productUrl = getProductPageUrlSync(line?.Description || '', line?.ProductId || 0, ctx.actionContext);
                            }
                            return (
                                <tr key={line.LineNum}>
                                    <td className={`${className}__products-table--col-1`}>{index + 1}</td>
                                    <td className={`${className}__products-table--col-2`}>
                                        <a href={productUrl}>
                                            {line.ItemNumber}
                                            {line.CustItemNumber && (
                                                <>
                                                    <br />
                                                    {`${custItemNumLabel} ${line.CustItemNumber}`}
                                                </>
                                            )}
                                        </a>
                                    </td>
                                    <td className={`${className}__products-table--col-3`}>
                                        <a href={productUrl}>{line.Description}</a>
                                    </td>
                                    <td className={`${className}__products-table--col-4`}>{line.Quantity}</td>
                                    <td className={`${className}__products-table--col-5`}>
                                        {formatAmount(ctx, line.ListPrice, currencyCode)}
                                    </td>
                                    <td className={`${className}__products-table--col-6`}>
                                        {formatAmount(ctx, line.UnitPrice, currencyCode)}
                                    </td>
                                    <td className={`${className}__products-table--col-7`}>{formatAmount(ctx, line.Total, currencyCode)}</td>
                                </tr>
                            );
                        })}

                    {invoiceLines &&
                        combinedFreightList &&
                        combinedFreightList.map((line, index) => {
                            return (
                                <tr key={invoiceLines.length + 1}>
                                    <td className={`${className}__products-table--col-1`}>{invoiceLines.length + index + 1}</td>
                                    <td className={`${className}__products-table--col-2`}>{line.FreightCode}</td>
                                    <td className={`${className}__products-table--col-3`}>{line.FreightDescription}</td>
                                    <td className={`${className}__products-table--col-4`}></td>
                                    <td className={`${className}__products-table--col-5`}></td>
                                    <td className={`${className}__products-table--col-6`}></td>
                                    <td className={`${className}__products-table--col-7`}>
                                        {formatAmount(ctx, line.FreightAmount, currencyCode)}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};
