import React from 'react';

export interface IEveQuickOrderHeaderResources {
    addAllToCartText: string;
    clearAllText: string;
}

interface IEveQuickOrderHeaderProps {
    heading: string;
    helpText: string;
    resources: IEveQuickOrderHeaderResources;
    canAddToCart: number;
    handleAddToCart: () => void;
    handleClearAll: () => void;
}

export const EveQuickOrderHeader: React.FC<IEveQuickOrderHeaderProps> = props => {
    const {
        heading,
        helpText
        // resources: { clearAllText, addAllToCartText },
        // canAddToCart,
        // handleClearAll,
        // handleAddToCart
    } = props;
    const _className = 'q-header';

    return (
        <div className={`${_className}`}>
            <div className={`${_className}__details`}>
                <h1 className={`${_className}__heading`}>{heading}</h1>
                {helpText && <p className={`${_className}__help-text`}>{helpText}</p>}
            </div>
            {/* <div className={`${_className}__controls`}>
                <button className={`${_className}__button--add-to-cart`} disabled={!canAddToCart} onClick={handleAddToCart} type={'button'}>
                    {addAllToCartText}
                </button>
                <button className={`${_className}__button--clear-form`} disabled={!canAddToCart} onClick={handleClearAll} type={'button'}>
                    {clearAllText}
                </button>
            </div> */}
        </div>
    );
};
