import React from 'react';
import Table from 'reactstrap/lib/Table';
import { IAriModel } from '../interfaces/IAriModel';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';

export interface IAriSearchResultTableResources {
    tableHeading: string;
    modelHeading: string;
    manufacturerHeading: string;
    descriptionHeading: string;
}

interface IAriSearchResultTableComponentProps {
    searchResults: IAriModel[];
    resources: IAriSearchResultTableResources;
    totalCount: number;
}

export const AriSearchResultTableComponent: React.FC<IAriSearchResultTableComponentProps> = props => {
    const { searchResults, resources, totalCount } = props;
    const { tableHeading, modelHeading, manufacturerHeading, descriptionHeading } = resources;
    const [outerWidth, setOuterWidth] = React.useState(0);

    React.useEffect(() => {
        //Set initial outerWidth
        setOuterWidth(window.outerWidth);
        //Resize window event handling
        window.removeEventListener('resize', () => setOuterWidth(window.outerWidth));
        window.addEventListener('resize', () => setOuterWidth(window.outerWidth), { passive: true });
        return window.removeEventListener('resize', () => setOuterWidth(window.outerWidth));
    });

    return (
        <div className='ari-search-results'>
            {outerWidth > 768 ? (
                <Table className={'list-view__table'}>
                    <thead>
                        <tr>
                            <th colSpan={3}>
                                {tableHeading} {totalCount} results
                            </th>
                        </tr>
                        <tr>
                            <th>{modelHeading}</th>
                            <th>{manufacturerHeading}</th>
                            <th>{descriptionHeading}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map(model => {
                            return (
                                <tr key={model.Id}>
                                    <td>
                                        <a href={model.detailPageUrl}>{model.Name}</a>
                                    </td>
                                    <td>{model.Description}</td>
                                    <td>{model.Name}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            ) : (
                <ListGroup>
                    {searchResults.map(model => {
                        return (
                            <ListGroupItem key={model.Id}>
                                <ListGroupItemHeading>
                                    <a href={model.detailPageUrl}>{model.Name}</a>
                                </ListGroupItemHeading>
                                <ListGroupItemText>
                                    <a href={model.detailPageUrl}>{model.Description}</a>
                                </ListGroupItemText>
                                <ListGroupItemText>
                                    <a href={model.detailPageUrl}>{model.Name}</a>
                                </ListGroupItemText>
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
            )}
        </div>
    );
};
