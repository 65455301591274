import * as React from 'react';

export interface IReportsExportCardProps {
    rowText: string;
    buttonText: string;
    className: string;
    onClick: (rowText: string) => Promise<void>;
    isButtonDisabled: boolean;
    dropdown?: React.ReactElement;
}

export const RenderCard: React.FC<IReportsExportCardProps> = ({ rowText, buttonText, className, onClick, isButtonDisabled, dropdown }) => {
    return (
        <>
            <div className={`${className}__heading`}>{rowText}</div>
            <>
                {dropdown ? <div className={`${className}__dropdown`}>{dropdown}</div> : null}
                <div className={`${className}__buttons`}>
                    <button
                        className={`${className}__buttons--download`}
                        disabled={isButtonDisabled}
                        onClick={() => onClick(rowText)}
                        type={'button'}
                    >
                        {buttonText}
                    </button>
                </div>
            </>
        </>
    );
};
