import { ISearchData, ISearchViewProps } from '@msdyn365-commerce-modules/search';
import { Node } from '@msdyn365-commerce-modules/utilities';
import React from 'react';

import { ISearchProps } from '../../definition-extensions/search.ext.props.autogenerated';
import { IAriServiceModelExtension } from "../../../../actions/DataServiceEntities.g";
import generateAriModelDetailUrl from "../../../../utils/getAriModelDetailUrl";

const renderAriSuggestionsTitle = (heading: string): JSX.Element =>
  (
    <div className="msc-autoSuggest__ariResults-title">
      {heading}
    </div>
  )


export const AriSuggestionsComponent = (
  noResultsText: string,
  props: ISearchViewProps & ISearchProps<ISearchData>,
  isLoading: boolean,
  searchResults: IAriServiceModelExtension[],
  loadingNode: React.ReactNode
): JSX.Element => {
  const { resources: { ariModelResultsHeading }, UlCategory } = props;
  return (
    <div className={'ms-search__autoSuggest__ari'}>
      {renderAriSuggestionsTitle(ariModelResultsHeading)}
      <Node { ...UlCategory } className={'msc-autoSuggest__ariResults-items'}>
        {isLoading? (
          loadingNode
        ) : (
          searchResults?.length ? (
            <>
              {searchResults.map(r => (<li className={"msc-autoSuggest__ariResults-item"}>
                <a href={generateAriModelDetailUrl(r.Id.toString(), r.BrandCode!, props.context.actionContext)}>
                  {r.Name}
                </a>
              </li>))}
            </>

          ) : (<li className={'msc-autoSuggest__ariResults-no-results'}>
            {noResultsText}
          </li>)
        )}

      </Node>
    </div>
  )
}
