import { IActionContext } from '@msdyn365-commerce/core-internal';
import { getUrlSync } from '@msdyn365-commerce/core';

export default function generateAriModelDetailUrl(modelId: string, brandCode: string, actionContext: IActionContext): string {
    const _modelDetailModelIdQueryParamKey = 'modelId';
    const _modelDetailBrandCodeQueryParamKey = 'brandCode';
    const _modelDetailSlug = 'model';
    let url = getUrlSync(_modelDetailSlug, actionContext);
    if (url) {
        url += `${
            url.indexOf('?') > -1 ? '&' : '?'
        }${_modelDetailModelIdQueryParamKey}=${modelId}&${_modelDetailBrandCodeQueryParamKey}=${brandCode}`;
    }

    return url || '';
}
